/**
 * @typedef {Object} Dimension
 * @property {number} height
 * @property {number} width
 */

/**
 * The function returns a object with height and width based on the aspect ratio
 *
 * @param {Object} [params={}] - The param object.
 * @param {number} [params.width=null]
 * @param {number} [params.height=null]
 * @param {string} [params.aspectRatio] The aspect ratio as `Number/Number`
 *
 * @returns {Dimension} Returns a object with height and width based on the aspect ratio
 */
export const calculateAspectRatio = ({ width = null, height = null, aspectRatio }) => {
    if (aspectRatio == null || aspectRatio.trim() === '') {
        throw new Error('Param `aspectRatio` is required');
    }

    const [w, h] = aspectRatio.split('/').map((n) => Number(n.trim()));

    if (isNaN(w) || typeof w !== 'number' || isNaN(h) || typeof h !== 'number') {
        throw new Error('Invalid `aspectRatio` format, it should be `Number/Number`.');
    }

    if (width != null) {
        return { height: (h * width) / w, width };
    }

    return { height, width: (height * w) / h };
};
