import window from '@/utils/window';

const createVideoComponentYouTube = () => {
    if (window.videoComponent?.youTube) {
        return;
    }

    // namespace to prevent collisions
    window.videoComponent = {
        youTube: {
            isLoading: false,
            isReady: false,
            stackReadyCallback: [],
            readyCallback() {
                window.videoComponent.youTube.isLoading = false;
                window.videoComponent.youTube.isReady = true;

                window.videoComponent.youTube.stackReadyCallback.forEach((fn) => fn());
            },
        },
    };
};

export const loadYouTubeIframeScript = (callback = () => {}) => {
    if (typeof window === 'undefined') {
        return;
    }

    createVideoComponentYouTube();

    const myYouTubeAPI = window.videoComponent.youTube;

    if (myYouTubeAPI.isReady) {
        callback();

        return;
    }

    myYouTubeAPI.stackReadyCallback.push(callback);

    if (myYouTubeAPI.isLoading) {
        return;
    }

    myYouTubeAPI.isLoading = true;

    const tag = document.createElement('script');

    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    window.onYouTubeIframeAPIReady = myYouTubeAPI.readyCallback;
};
