import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { OverlayTriggerStateContext } from 'react-aria-components';

import { Button } from '@/design-system/atoms/Button';
import { LazyIconX } from '@/design-system/atoms/Icons/IconX/Lazy';
import { Theme } from '@/design-system/atoms/Theme';

import { useBreakpoint } from '../../../hooks/useBreakpoint';
import { default as OverlayModal } from './components/OverlayModal';
import { default as SheetModal } from './components/SheetModal';
import styles from './Modals.module.scss';

//Reusable close button for desktop and mobile
const CloseButton = ({ className }) => {
    let state = React.useContext(OverlayTriggerStateContext);

    return (
        <button
            type="button"
            className={classnames(className, styles.closeButton)}
            onClick={() => {
                state.close();
            }}
        >
            <LazyIconX size={20} />
        </button>
    );
};
CloseButton.propTypes = {
    className: PropTypes.string,
};

const findChildByDisplayName = (children, displayName, props = {}) => {
    const childArray = React.Children.map(children, (child) =>
        child.type.displayName === displayName ? React.cloneElement(child, props) : null,
    );

    if (childArray.length > 1) {
        throw new Error(`Cannot have multiple ${displayName} inside a Modal component.`);
    }

    return childArray[0];
};

const ContentModal = ({
    children,
    isVisible = false,
    onClose,
    theme = Theme.NAME.DEFAULT,
    className,
    ...props
}) => {
    const breakpoint = useBreakpoint();
    const ModalComponent = breakpoint?.medium ? OverlayModal : SheetModal;
    const ContentModalFooter = findChildByDisplayName(children, 'ContentModalFooter');
    const ContentModalContent = findChildByDisplayName(children, 'ContentModalContent');
    return (
        <div {...props}>
            <ModalComponent isVisible={isVisible} onClose={onClose}>
                <Theme theme={theme} className={styles.contentWrapper}>
                    <>
                        {ContentModalContent}
                        {ContentModalFooter}
                    </>
                </Theme>
            </ModalComponent>
        </div>
    );
};

const Content = ({ children, className, closeButtonClass, ...props }) => {
    return (
        <div {...props}>
            <CloseButton className={closeButtonClass} />
            <div className={classnames(styles.content, className)}>{children}</div>
        </div>
    );
};
Content.displayName = 'ContentModalContent';
ContentModal.Content = Content;

const Footer = ({ children, className, ...props }) => {
    let state = React.useContext(OverlayTriggerStateContext);
    return (
        <div className={classnames(styles.footer, className)} {...props}>
            {Array.isArray(children) && children !== undefined ? (
                <>{children}</>
            ) : (
                <Button
                    label="Close"
                    onClick={() => {
                        state.close();
                    }}
                />
            )}
        </div>
    );
};
Footer.displayName = 'ContentModalFooter';
ContentModal.Footer = Footer;

ContentModal.propTypes = {
    children: PropTypes.node,
    isVisible: PropTypes.bool,
    onClose: PropTypes.func,
};

export default ContentModal;
