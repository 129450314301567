import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React from 'react';
import { Dialog, Modal as RACModal, ModalOverlay } from 'react-aria-components';

import styles from '../Modals.module.scss';

//Mobile Sheet modal and overlay with animations from framer-motion
const SheetModal = ({ children, isVisible = false, onClose }) => {
    // Wrap React Aria modal components so they support framer-motion values.
    const MotionModal = motion(RACModal);
    const MotionModalOverlay = motion(ModalOverlay);

    //Animation values for Mobile Sheet open/close
    // TODO: convene with Stephen Varga on what this animation should be
    const staticTransition = {
        duration: 0.5,
        ease: [0.32, 0.72, 0, 1],
    };

    const backdropVisibleStyle = {
        background: 'rgba(1, 29, 70, .7)',
        backdropFilter: 'blur(8px) opacity(0.7)',
    };
    const backdropHiddenStyle = {
        background: 'rgba(1, 29, 70, 0)',
        backdropFilter: 'blur(0px) opacity(0)',
    };
    const contentVisibleStyle = {
        bottom: 0,
    };
    const contentHiddenStyle = {
        bottom: '-100%',
    };

    return (
        <>
            <AnimatePresence>
                {isVisible && (
                    <MotionModalOverlay
                        // Force the modal to be open when AnimatePresence renders it.
                        isOpen={isVisible}
                        onOpenChange={onClose}
                        className={` ${styles.sheetOverlay}`}
                        initial={backdropHiddenStyle}
                        animate={backdropVisibleStyle}
                        exit={backdropHiddenStyle}
                        transition={staticTransition}
                    >
                        <MotionModal
                            className={`will-change-transform ${styles.sheet}`}
                            initial={contentHiddenStyle}
                            animate={contentVisibleStyle}
                            exit={contentHiddenStyle}
                            transition={staticTransition}
                        >
                            <Dialog>{children}</Dialog>
                        </MotionModal>
                    </MotionModalOverlay>
                )}
            </AnimatePresence>
        </>
    );
};
SheetModal.propTypes = {
    children: PropTypes.node,
    isVisible: PropTypes.bool,
    onClose: PropTypes.func,
};
export default SheetModal;
