import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { LazyIconVideoPlayCircle } from '@/design-system/atoms/Icons/IconVideoPlayCircle/Lazy';
import { LazyIconX } from '@/design-system/atoms/Icons/IconX/Lazy';

import styles from './ButtonMedia.module.scss';

export const ButtonMedia = React.forwardRef((props, ref) => {
    const { component: Component = 'button', className, icon, ...otherProps } = props;

    return (
        <Component
            ref={ref}
            className={classnames(
                styles['button-media'],
                icon === 'play' && styles['button-media--play'],
                icon === 'close' && styles['button-media--close'],
                className,
            )}
            type="button"
            {...otherProps}
        >
            {icon === 'play' && (
                <LazyIconVideoPlayCircle
                    className={classnames(
                        styles['button-media__icon'],
                        styles['button-media__icon--play'],
                    )}
                />
            )}
            {icon === 'close' && (
                <LazyIconX
                    className={classnames(
                        styles['button-media__icon'],
                        styles['button-media__icon--close'],
                    )}
                />
            )}
        </Component>
    );
});

ButtonMedia.displayName = 'ButtonMedia';

ButtonMedia.propTypes = {
    icon: PropTypes.oneOf(['play', 'close']),
};
