import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import * as customPropTypes from '@/custom-prop-types';
import { trackInteraction } from '@/utils/analytics';
import { loadDependency } from '@/utils/scriptLoader';

import styles from '../../Video.module.scss';

export const DAMVideo = React.forwardRef((props, ref) => {
    const {
        className,
        id,
        autoplay,
        duration,
        name,
        asset,
        videoServerUrl,
        captionUrl,
        posterImage,
        serverUrl,
        analytics,
        videoPlayerRef,
        ...otherProps
    } = props;
    const player = useRef(null);

    const [isReady, setIsReady] = useState(false);

    const onPlayerStateChange = useCallback(
        (event) => {
            if (analytics) {
                const analyticsObj = {
                    eventName: 'video',
                    componentName: 'video_card_player',
                    componentTitle: analytics?.componentTitle,
                    actionLabel: analytics?.actionLabel,
                    selector: analytics?.selector || 'video',
                };

                switch (event) {
                    case 'ENDED':
                        trackInteraction({
                            ...analyticsObj,
                            interactionType: 'video_end',
                        });
                        break;

                    case 'PLAY':
                        trackInteraction({
                            ...analyticsObj,
                            interactionType: 'play',
                        });
                        break;

                    case 'PAUSE':
                        trackInteraction({
                            ...analyticsObj,
                            interactionType: 'pause',
                        });
                        break;
                    default:
                }
            }
        },
        [analytics],
    );

    useEffect(() => {
        loadDependency('//s7d1.scene7.com/s7viewers/html5/js/VideoViewer.js', (success) => {
            if (success && !isReady && window.s7viewers) {
                setIsReady(true);

                player.current = new window.s7viewers.VideoViewer({
                    containerId: id,
                    params: {
                        asset: asset,
                        serverurl: serverUrl,
                        videoserverurl: videoServerUrl,
                        posterimage: posterImage,
                        caption: captionUrl,
                        autoplay: autoplay,
                        duration: duration,
                        name: name,
                    },
                    handlers: {
                        trackEvent: function (objID, compClass, instName, timeStamp, eventInfo) {
                            //identify event type
                            var eventType = eventInfo.split(',')[0];
                            var eventData = eventInfo.split(',')[1];
                            if (eventType === 'PLAY' || eventType === 'PAUSE') {
                                onPlayerStateChange(eventType);
                            } else if (eventType === 'MILESTONE' && eventData === '100') {
                                onPlayerStateChange('ENDED');
                            }
                        },
                    },
                }).init();

                if (videoPlayerRef) {
                    videoPlayerRef.current = player.current;
                }
            }
        });
    }, [
        isReady,
        setIsReady,
        id,
        asset,
        serverUrl,
        videoServerUrl,
        posterImage,
        captionUrl,
        autoplay,
        duration,
        name,
        onPlayerStateChange,
        videoPlayerRef,
    ]);

    useEffect(() => {
        // Dispose of the video player when the component is unmounted
        return () => {
            if (player.current && player.current?.dispose) {
                player.current.dispose();
            }
        };
    }, []);

    return (
        <div
            id={id}
            {...otherProps}
            ref={ref}
            className={classnames(styles.video, className)}
        ></div>
    );
});

DAMVideo.displayName = 'DAMVideo';

DAMVideo.propTypes = {
    /**
     * The ID for the video container
     */
    id: PropTypes.string,
    /**
     * The Asset for referencing the video
     */
    asset: PropTypes.string,
    /**
     * The Server URL for the video

     */
    serverUrl: PropTypes.string,
    /**
     * The Video Server URL for the video
     */
    videoServerUrl: PropTypes.string,
    /**
     * The Poster Image for the video
     */
    posterImage: PropTypes.string,
    /**
     * The Caption URL for the video
     */
    captionUrl: PropTypes.string,
    /**
     * Autoplay State
     */
    autoplay: PropTypes.bool,
    /**
     * Duration of the video
     */
    duration: PropTypes.number,
    /**
     * Name of the video
     */
    name: PropTypes.string,
    /**
     * Analytics
     */
    analytics: customPropTypes.analyticsPropType,
    /**
     * Optional ref for the video player. Useful for controlling the video player from a parent component. E.g disposing it
     */
    videoPlayerRef: PropTypes.object,
};
