/*
 * Load Dependency: Load a dependency script and call a callback when it's loaded
 * @param {string} urlPath
 * @param {function} onComplete
 */
const scripts = [];

export function loadDependency(urlPath, onComplete) {
    if (scripts.includes(urlPath)) {
        onComplete(true);
        return;
    }

    const headElm = document.querySelector('head');
    const script = document.createElement('script');
    script.src = urlPath;
    //script.defer = true;

    script.onload = function onDependencyLoad() {
        onComplete(true);
        scripts.push(urlPath);
    };
    script.onerror = function onDependencyError() {
        onComplete(false);
    };

    headElm.appendChild(script);
}
