import PropTypes from 'prop-types';
import React from 'react';

import { DAMVideo } from './components/DAM/DAMVideo';
import { YoutubeVideo } from './components/Youtube/YoutubeVideo';

/**
 * The Video Component displays a video player. It accepts either a DAM Adobe Video or a YouTube Video.
 */
export const Video = React.forwardRef((props, ref) => {
    const { videotype, ...otherProps } = props;

    return (
        <>
            {videotype === 'adobe' ? (
                <DAMVideo ref={ref} {...otherProps} />
            ) : (
                <YoutubeVideo ref={ref} {...otherProps} />
            )}
        </>
    );
});

Video.displayName = 'Video';

Video.propTypes = {
    /**
     * The type of video to display
     */
    videotype: PropTypes.oneOf(['adobe', 'youtube']).isRequired,
    ...DAMVideo.propTypes,
};
