import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { OverlayTriggerStateContext } from 'react-aria-components';

import * as customPropTypes from '@/custom-prop-types';
import { ButtonMedia } from '@/design-system/atoms/ButtonMedia';
import { Video } from '@/design-system/atoms/Video';

import { default as OverlayModal } from './components/OverlayModal';
import styles from './Modals.module.scss';

const CloseButton = ({ className }) => {
    let state = React.useContext(OverlayTriggerStateContext);

    return (
        <div className={classnames(styles.videoCloseButton, className)}>
            <ButtonMedia
                icon="close"
                onClick={() => {
                    state.close();
                }}
            />
        </div>
    );
};

const VideoModal = ({
    video,
    width,
    height,
    aspectRatio = '16/9',
    isVisible = false,
    onClose,
    analytics,
    ...props
}) => {
    const ModalComponent = OverlayModal;
    const videoRef = useRef();
    const videoPlayerRef = useRef();

    const onCloseCallback = () => {
        // Dispose of the video player when the modal is closed
        // Fix for the way the DM video player handles being removed from the DOM
        if (videoPlayerRef.current && videoPlayerRef.current?.dispose) {
            videoPlayerRef.current.dispose();
        }
        onClose();
    };

    return (
        <div {...props}>
            <ModalComponent
                isVisible={isVisible}
                onClose={onCloseCallback}
                media
                style={{ '--aspect-ratio': aspectRatio }}
            >
                <div className={styles.videoWrapper}>
                    <CloseButton />
                    <Video
                        ref={videoRef}
                        videoPlayerRef={videoPlayerRef}
                        {...video}
                        aspectRatio={aspectRatio}
                        analytics={analytics}
                    />
                </div>
            </ModalComponent>
        </div>
    );
};

VideoModal.propTypes = {
    video: PropTypes.shape(Video.propTypes),
    aspectRatio: customPropTypes.aspectRatio,
    isVisible: PropTypes.bool,
    onClose: PropTypes.func,
};

export default VideoModal;
