import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import * as customPropTypes from '@/custom-prop-types';
import { trackInteraction } from '@/utils/analytics';
import { calculateAspectRatio } from '@/utils/calculateAspectRatio';
import { debounce } from '@/utils/debounce';

import { loadYouTubeIframeScript } from '../../utils/loadYouTubeIframeScript';
import styles from '../../Video.module.scss';

const noop = () => {};
const emptyObj = {};

export const YoutubeVideo = React.forwardRef((props, ref) => {
    const {
        className,
        height: heightProp,
        width: widthProp,
        aspectRatio = '16/9',
        onReadyChange = noop,
        videoId,
        youTubeIframeEvents = emptyObj,
        youTubeIframePlayerVars = emptyObj,
        analytics,
        videotype,
        ...otherProps
    } = props;

    const heightRef = React.useRef(heightProp);
    React.useEffect(() => {
        heightRef.current = heightProp;
    }, [heightProp]);

    const widthRef = React.useRef(widthProp);
    React.useEffect(() => {
        widthRef.current = widthProp;
    }, [widthProp]);

    const aspectRatioRef = React.useRef(aspectRatio);
    React.useEffect(() => {
        aspectRatioRef.current = aspectRatio;
    }, [aspectRatio]);

    const getSize = React.useCallback((wrapperWidth) => {
        if (heightRef.current != null && widthRef.current != null) {
            return { height: heightRef.current, width: widthRef.current };
        }

        if (heightRef.current != null) {
            return calculateAspectRatio({
                height: heightRef.current,
                aspectRatio: aspectRatioRef.current,
            });
        }

        if (widthRef.current != null) {
            return calculateAspectRatio({
                width: widthRef.current,
                aspectRatio: aspectRatioRef.current,
            });
        }

        return calculateAspectRatio({ width: wrapperWidth, aspectRatio: aspectRatioRef.current });
    }, []);

    const wrapperRef = React.useRef();

    const playerElementId = React.useId();
    const youTubeIframeAPIRef = React.useRef();

    const [isYouTubeAPILoaded, setIsYouTubeAPILoaded] = React.useState(false);
    const [isReady, setIsReady] = React.useState(false);
    const isReadyRef = React.useRef(isReady);

    const onReadyChangeRef = React.useRef(onReadyChange);

    React.useEffect(() => {
        onReadyChangeRef.current = onReadyChange;
    }, [onReadyChange]);

    const firstRenderRef = React.useRef(true);
    React.useEffect(() => {
        if (firstRenderRef.current) {
            firstRenderRef.current = false;
            return;
        }

        isReadyRef.current = isReady;
        onReadyChangeRef.current(isReady);
    }, [isReady]);

    React.useEffect(() => {
        loadYouTubeIframeScript(() => setIsYouTubeAPILoaded(true));
    }, []);

    const previewHeightRef = React.useRef();
    const previewWidthRef = React.useRef();
    React.useLayoutEffect(() => {
        const wrapperWidth = wrapperRef.current.offsetWidth ?? window?.innerWidth ?? 320;
        const { height, width } = getSize(wrapperWidth);
        const onPlayerStateChange = (event) => {
            youTubeIframeEvents.onStateChange?.(event);
            if (analytics) {
                const analyticsObj = {
                    eventName: 'video',
                    componentName: 'video_card_player',
                    componentTitle: analytics?.componentTitle,
                    actionLabel: analytics?.actionLabel,
                    selector: analytics?.selector || 'video',
                };

                switch (event.data) {
                    case window.YT.PlayerState.ENDED:
                        trackInteraction({
                            ...analyticsObj,
                            interactionType: 'video_end',
                        });
                        break;

                    case window.YT.PlayerState.PLAYING:
                        trackInteraction({
                            ...analyticsObj,
                            interactionType: 'play',
                        });
                        break;

                    case window.YT.PlayerState.PAUSED:
                        trackInteraction({
                            ...analyticsObj,
                            interactionType: 'pause',
                        });
                        break;
                    default:
                }
            }
        };

        previewHeightRef.current = height;
        previewWidthRef.current = width;

        wrapperRef.current.style.height = `${height}px`;

        if (!isYouTubeAPILoaded) {
            return;
        }

        youTubeIframeAPIRef.current = new window.YT.Player(playerElementId, {
            height,
            width,
            videoId,
            playerVars: {
                playsinline: 1,
                ...youTubeIframePlayerVars,
            },
            events: {
                ...youTubeIframeEvents,
                onReady: (...args) => {
                    wrapperRef.current.style.height = 'auto';

                    youTubeIframeEvents.onReady?.(...args);
                    setIsReady(true);
                },
                onStateChange: onPlayerStateChange,
            },
        });

        return () => {
            youTubeIframeAPIRef.current && youTubeIframeAPIRef.current.destroy();
        };
    }, [
        youTubeIframePlayerVars,
        youTubeIframeEvents,
        isYouTubeAPILoaded,
        playerElementId,
        videoId,
        getSize,
        analytics,
    ]);

    React.useLayoutEffect(() => {
        if (!isReady) {
            return;
        }

        let requestAnimationFrameId = null;

        const updateWidth = () => {
            const debouncedResize = debounce((width, height) => {
                if (!youTubeIframeAPIRef.current) {
                    return;
                }

                youTubeIframeAPIRef.current.setSize(width, height);
            }, 1000);

            const step = () => {
                const wrapperWidth = wrapperRef.current.offsetWidth;
                const { height, width } = getSize(wrapperWidth);

                if (height !== previewHeightRef.current || width !== previewWidthRef.current) {
                    previewHeightRef.current = height;
                    previewWidthRef.current = width;

                    debouncedResize(width, height);
                }

                requestAnimationFrameId = window.requestAnimationFrame(step);
            };

            requestAnimationFrameId = window.requestAnimationFrame(step);
        };

        updateWidth();

        return () => {
            window.cancelAnimationFrame(requestAnimationFrameId);
        };
    }, [getSize, isReady]);

    React.useImperativeHandle(
        ref,
        () => ({
            getIsReady() {
                return isReadyRef.current;
            },
            getYouTubeIframeAPI() {
                return youTubeIframeAPIRef.current;
            },
        }),
        [],
    );

    return (
        <div ref={wrapperRef} className={classnames(styles.video, className)} {...otherProps}>
            <div
                id={playerElementId}
                data-trigger={analytics ? analytics?.selector || 'video' : undefined}
            />
        </div>
    );
});

YoutubeVideo.displayName = 'Video';

YoutubeVideo.propTypes = {
    /**
     * YouTube Video ID
     */
    videoId: PropTypes.string,
    /**
     * Video Height
     */
    height: PropTypes.number,
    /**
     * Video Width
     */
    width: PropTypes.number,
    /**
     * Video Aspect Ratio
     */
    aspectRatio: customPropTypes.aspectRatio,
    /**
     * Function that will ne called every time that te `isReady` state changes
     */
    onReadyChange: PropTypes.func,
    /**
     * A object with the same props of the
     * [YouTubeIframeAPI Events](https://developers.google.com/youtube/iframe_api_reference#Events)
     */
    youTubeIframeEvents: PropTypes.object,
    /**
     * A object with the same props of the
     * [YouTubeIframeAPI Player Parameters](https://developers.google.com/youtube/player_parameters)
     */
    youTubeIframePlayerVars: PropTypes.object,
    /**
     * Analytics
     */
    analytics: customPropTypes.analyticsPropType,
};
