import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Dialog, Modal as RACModal, ModalOverlay } from 'react-aria-components';

import styles from '../Modals.module.scss';

//Desktop Modal overlay and popup
const OverlayModal = ({ children, isVisible = false, onClose, media, ...props }) => {
    return (
        <ModalOverlay
            className={({ isEntering, isExiting }) =>
                `${classnames(
                    styles.overlay,
                    isEntering && styles.animateIn,
                    isExiting && styles.animateOut,
                )}`
            }
            isOpen={isVisible}
            onOpenChange={onClose}
            {...props}
        >
            <RACModal
                isOpen={isVisible}
                onOpenChange={onClose}
                className={({ isEntering, isExiting }) =>
                    `${classnames(
                        styles.modal,
                        media && styles.videoModal,
                        isEntering && styles.animateIn,
                        isExiting && styles.animateOut,
                    )}`
                }
            >
                <Dialog role="alertdialog" className={styles.dialog}>
                    {children}
                </Dialog>
            </RACModal>
        </ModalOverlay>
    );
};
OverlayModal.propTypes = {
    children: PropTypes.node,
    isVisible: PropTypes.bool,
    onClose: PropTypes.func,
};

export default OverlayModal;
