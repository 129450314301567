/**
 * Creates a debounced function that delays invoking func until after wait
 * milliseconds have elapsed since the last time the debounced function was
 * invoked. The debounced function comes with a `cancel` method to cancel delayed
 * func invocations.
 *
 * @param {Function} callback The function to debounce.
 * @param {number} wait The number of milliseconds to delay.
 * @returns {Function} Returns the new debounced function.
 */
export const debounce = (callback, wait = 500) => {
    let timeoutId = null;
    const debouncedFunction = (...args) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            callback(...args);
        }, wait);
    };

    debouncedFunction.cancel = () => {
        clearTimeout(timeoutId);
    };

    return debouncedFunction;
};
